<div fxFill fxLayout="column" fxLayoutAlign="center">
  <app-header></app-header>
  <mat-toolbar class="background-white title-toolbar">
    <label class="step-title">Recuperar contraseña</label>
  </mat-toolbar>
  <mat-divider></mat-divider>
  <div class="login-container" fxLayout="row" fxLayoutAlign="center center">
    <mat-card fxFlex="75%" class="login-box">
      <p>
        Para recuperar tu contraseña, escribe el correo electrónico con el que te registraste y te enviaremos un enlace
        para que recuperes el acceso a tu cuenta.
      </p>

      <form (ngSubmit)="sendEmail()" [formGroup]="passwordForm" novalidate>
        <!--<div class="login-fields" fxLayout="column">-->
        <div class="login-fields">
          <mat-form-field [hideRequiredMarker]="true" appearance="fill">
            <mat-label>Correo electrónico</mat-label>
            <mat-icon matPrefix>mail</mat-icon>
            <input
              type="text"
              matInput
              formControlName="username"
              autocomplete="username"
              [placeholder]="'Usuario' | translate"
              required
            />
            <mat-error *ngIf="passwordForm.controls.username.invalid && passwordForm.controls.username.touched">
              <span translate>Correo electrónico requerido</span>
            </mat-error>
          </mat-form-field>

          <button
            mat-raised-button
            class="login-btn"
            color="primary"
            type="submit"
            [disabled]="passwordForm.invalid || isLoading"
          >
            <app-loader class="inline-loader" [isLoading]="isLoading"></app-loader>
            <span translate>ENVIAR ENLACE</span>
          </button>
        </div>
      </form>
    </mat-card>
  </div>
  <app-footer></app-footer>
</div>
