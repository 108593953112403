<div class="section-main-container">
  <mat-card class="form-card">
    <section-header
      section="I. Declaración de situación patrimonial"
      [simplificada]="declaracionSimplificada"
      progress="4.5"
      type="{{ tipoDeclaracion }}"
    ></section-header>

    <mat-divider></mat-divider>

    <mat-toolbar class="background-white">
      <label class="step-title"> 1. Datos Generales</label>
    </mat-toolbar>

    <mat-divider></mat-divider>

    <p class="vertical-margin-25">
      Todos los campos * son obligatorios. Los datos de terceros, siempre y cuando sean personas físicas, y los campos
      resaltados <span class="no-public-inline"></span> no serán públicos.
    </p>

    <div class="grid-3-col">
      <mat-form-field appearance="fill">
        <mat-label>Año del ejercicio</mat-label>
        <mat-icon matPrefix>date_range</mat-icon>
        <input
          [errorStateMatcher]="errorMatcher"
          matInput
          type="number"
          [(ngModel)]="anio_ejercicio"
          min="0"
          max="{{ currentYear }}"
          placeholder="{{ currentYear }}"
        />
      </mat-form-field>
    </div>
    <form (ngSubmit)="confirmSaveInfo()" [formGroup]="datosGeneralesForm">
      <div class="grid-3-col grid-margin">
        <mat-form-field matTooltip="{{ tooltipData.nombre }}" appearance="fill">
          <mat-label>Nombre</mat-label>
          <mat-icon matPrefix>person</mat-icon>
          <input [errorStateMatcher]="errorMatcher" matInput type="text" formControlName="nombre" required />
        </mat-form-field>
        <mat-form-field matTooltip="{{ tooltipData.nombre }}" appearance="fill">
          <mat-label>Primer Apellido</mat-label>
          <mat-icon matPrefix>person</mat-icon>
          <input [errorStateMatcher]="errorMatcher" matInput type="text" formControlName="primerApellido" required />
        </mat-form-field>
        <mat-form-field matTooltip="{{ tooltipData.nombre }}" appearance="fill">
          <mat-label>Segundo Apellido</mat-label>
          <mat-icon matPrefix>person</mat-icon>
          <input [errorStateMatcher]="errorMatcher" matInput type="text" formControlName="segundoApellido" />
        </mat-form-field>

        <mat-form-field matTooltip="{{ tooltipData.curp }}" appearance="fill">
          <mat-label>CURP</mat-label>
          <mat-icon matPrefix class="no-public">fingerprint</mat-icon>
          <input
            [errorStateMatcher]="errorMatcher"
            matInput
            #input_curp
            maxlength="18"
            type="text"
            formControlName="curp"
            required
          />
          <mat-hint align="end">{{ input_curp.value?.length || 0 }}/18</mat-hint>
        </mat-form-field>

        <mat-form-field matTooltip="{{ tooltipData.rfc }}" appearance="fill" formGroupName="rfc">
          <mat-label>RFC</mat-label>
          <mat-icon matPrefix class="no-public">fingerprint</mat-icon>
          <input
            [errorStateMatcher]="errorMatcher"
            matInput
            #input_rfc
            maxlength="10"
            type="text"
            formControlName="rfc"
            required
          />
          <mat-hint align="end">{{ input_rfc.value?.length || 0 }}/10</mat-hint>
        </mat-form-field>
        <mat-form-field matTooltip="{{ tooltipData.rfc }}" appearance="fill" formGroupName="rfc">
          <mat-label>Homoclave</mat-label>
          <mat-icon matPrefix class="no-public">fingerprint</mat-icon>
          <input
            [errorStateMatcher]="errorMatcher"
            matInput
            #input_homoclave
            maxlength="3"
            type="text"
            formControlName="homoClave"
            required
          />
          <mat-hint align="end">{{ input_homoclave.value?.length || 0 }}/3</mat-hint>
        </mat-form-field>
      </div>

      <div class="grid-2-col grid-margin">
        <mat-form-field
          matTooltip="{{ tooltipData.correoInstitucional }}"
          appearance="fill"
          formGroupName="correoElectronico"
        >
          <mat-label>Correo Electrónico Institucional</mat-label>
          <mat-icon matPrefix>mail</mat-icon>
          <input [errorStateMatcher]="errorMatcher" matInput type="email" formControlName="institucional" />
        </mat-form-field>
        <mat-form-field
          matTooltip="{{ tooltipData.correoPersonal }}"
          appearance="fill"
          formGroupName="correoElectronico"
        >
          <mat-label>Correo Electrónico Personal / Alterno</mat-label>
          <mat-icon matPrefix class="no-public">mail</mat-icon>
          <input [errorStateMatcher]="errorMatcher" matInput type="email" formControlName="personal" required />
        </mat-form-field>

        <mat-form-field matTooltip="{{ tooltipData.telefonoCasa }}" appearance="fill" formGroupName="telefono">
          <mat-label>Número Telefónico de Casa</mat-label>
          <mat-icon matPrefix class="no-public">local_phone</mat-icon>
          <input
            [errorStateMatcher]="errorMatcher"
            matInput
            #input_tel_casa
            maxlength="10"
            type="tel"
            formControlName="casa"
            required
          />
          <mat-hint align="end">{{ input_tel_casa.value?.length || 0 }}/10</mat-hint>
        </mat-form-field>
        <mat-form-field matTooltip="{{ tooltipData.telefonoCelular }}" appearance="fill" formGroupName="telefono">
          <mat-label>Número Celular Personal</mat-label>
          <mat-icon matPrefix class="no-public">stay_primary_portrait</mat-icon>
          <input
            [errorStateMatcher]="errorMatcher"
            matInput
            #input_tel_celular
            maxlength="10"
            type="tel"
            formControlName="celularPersonal"
            required
          />
          <mat-hint align="end">{{ input_tel_celular.value?.length || 0 }}/10</mat-hint>
        </mat-form-field>

        <mat-form-field matTooltip="{{ tooltipData.estadoCivil }}" appearance="fill">
          <mat-label>Situación Personal / Estado Civil</mat-label>
          <mat-icon matPrefix class="no-public">people</mat-icon>
          <mat-select formControlName="situacionPersonalEstadoCivil" required>
            <mat-option *ngFor="let opt of situacionPersonalEstadoCivilCatalogo" [value]="opt">
              {{ opt.valor }}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field matTooltip="{{ tooltipData.regimenMatrimonial }}" appearance="fill">
          <mat-label>Régimen Matrimonial</mat-label>
          <mat-icon matPrefix class="no-public">people</mat-icon>
          <mat-select formControlName="regimenMatrimonial" required>
            <mat-option *ngFor="let opt of regimenMatrimonialCatalogo" [value]="opt">
              {{ opt.valor }}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field
          [ngClass]="{ hidden: datosGeneralesForm.value.regimenMatrimonial?.clave !== 'OTR' }"
          appearance="fill"
        >
          <mat-label>Especifique Régimen Matrimonial</mat-label>
          <mat-icon matPrefix class="no-public">people</mat-icon>
          <input
            [errorStateMatcher]="errorMatcher"
            #otroRegimenMatrimonial
            matInput
            minlength="1"
            type="text"
            required
          />
        </mat-form-field>

        <mat-form-field matTooltip="{{ tooltipData.paisNacimiento }}" appearance="fill">
          <mat-label>País de Nacimiento</mat-label>
          <mat-icon matPrefix class="no-public">public</mat-icon>
          <mat-select formControlName="paisNacimiento" required>
            <mat-option *ngFor="let opt of paisesCatalogo" [value]="opt.clave">
              {{ opt.valor }}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field matTooltip="{{ tooltipData.nacionalidad }}" appearance="fill">
          <mat-label>Nacionalidad</mat-label>
          <mat-icon matPrefix class="no-public">public</mat-icon>
          <mat-select formControlName="nacionalidad" required>
            <mat-option *ngFor="let opt of nacionalidadesCatalogo" [value]="opt.clave">
              {{ opt.valor }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <mat-grid-list *ngIf="!aclaraciones" cols="15" rowHeight="100px">
        <mat-grid-tile class="content-left" colspan="15">
          <button mat-raised-button type="button" class="text-primary" (click)="toggleAclaraciones(true)">
            AGREGAR ACLARACIONES / OBSERVACIONES
          </button>
        </mat-grid-tile>
      </mat-grid-list>

      <div *ngIf="aclaraciones">
        <div class="flex grid-margin">
          <p>Aclaraciones / Observaciones</p>
          <button mat-raised-button color="primary" (click)="toggleAclaraciones(false)">CANCELAR</button>
        </div>

        <mat-form-field appearance="fill" class="grid-margin">
          <mat-icon matPrefix class="aclaraciones-no-public">speaker_notes</mat-icon>
          <textarea
            maxlength="500"
            [errorStateMatcher]="errorMatcher"
            matInput
            formControlName="aclaracionesObservaciones"
            rows="10"
          ></textarea>
        </mat-form-field>
      </div>

      <div class="boton text-right">
        <app-loader class="inline-loader" [isLoading]="isLoading"></app-loader>
        <button mat-raised-button color="primary" type="button" (click)="datosGeneralesForm.reset()">
          LIMPIAR FORMULARIO
        </button>
        <button
          mat-raised-button
          color="primary"
          [disabled]="datosGeneralesForm.invalid || !inputsAreValid() || isLoading"
        >
          GUARDAR CAMBIOS
        </button>
      </div>
    </form>

    <mat-divider></mat-divider>

    <div class="boton center">
      <button *ngIf="!declaracionSimplificada" mat-raised-button color="primary" (click)="formHasChanges()">
        CONTINUAR 2 DE 15
        <mat-icon matSofix>arrow_forward</mat-icon>
      </button>
      <button *ngIf="declaracionSimplificada" mat-raised-button color="primary" (click)="formHasChanges()">
        CONTINUAR 2 DE 7
        <mat-icon matSofix>arrow_forward</mat-icon>
      </button>
    </div>

    <section-footer [declaracionId]="declaracionId"></section-footer>
  </mat-card>
</div>
