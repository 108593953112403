import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-llenado-de-declaraciones',
  templateUrl: './llenado-de-declaraciones.component.html',
  styleUrls: ['./llenado-de-declaraciones.component.scss', '../sections.scss'],
})
export class LlenadoDeDeclaracionesComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
