<div class="section-main-container">
  <mat-card class="form-card">
    <section-header section="II. Declaración de intereses" progress="90" type="{{ tipoDeclaracion }}"></section-header>

    <mat-divider></mat-divider>

    <mat-toolbar class="background-white">
      <label class="step-title"> 5. Clientes principales (hasta los últimos dos años)</label>
    </mat-toolbar>

    <mat-divider></mat-divider>

    <p class="vertical-margin-25">
      Todos los campos * son obligatorios. Todos los datos de clientes principales de la pareja o dependientes
      económicos no serán públicos y los campos resaltados <span class="no-public-inline"></span> no serán públicos.
    </p>
    <p>
      En caso de tener alguna empresa, negocio o actividad lucrativa deberá señalar a sus clientes principales, siempre
      y cuando el beneficio o ganancia directa al Declarante supere mensualmente 250 Unidades de Medida y Actualización
      (UMA), refiriéndose al valor diario de ésta. Deberá reportar hasta los últimos dos años.
    </p>

    <!-- Botones iniciales -->
    <div *ngIf="!cliente?.length && !editMode && !clientesPrincipalesForm.value.ninguno">
      <mat-grid-list cols="2" rowHeight="150px">
        <mat-grid-tile colspan="1">
          <div class="boton-agregar">
            <button mat-raised-button color="primary" (click)="noClients()">Ninguno</button>
          </div>
        </mat-grid-tile>
        <mat-grid-tile colspan="1">
          <div class="boton-agregar">
            <button mat-raised-button color="primary" (click)="addItem()">Agregar cliente</button>
          </div>
        </mat-grid-tile>
      </mat-grid-list>
    </div>

    <!-- card de información -->
    <div class="">
      <mat-card class="info" *ngIf="clientesPrincipalesForm.value.ninguno">
        <p>Ningún Cliente Principal</p>
      </mat-card>

      <mat-card class="info" *ngFor="let c of cliente; let i = index">
        <mat-card-content class="">
          <div class="paragraph-flex size-20">
            <mat-icon matPrefix class="icon">person</mat-icon>
            <p>
              <span class="negritas">{{ c.tipoRelacion }}</span>
            </p>
          </div>
          <div class="grid-3fr-1fr">
            <div class="paragraph-flex">
              <mat-icon matPrefix class="icon card-no-public">work</mat-icon>
              <p>{{ c.empresa.nombreEmpresaServicio }}</p>
            </div>
            <div class="paragraph-flex size-20">
              <mat-icon matPrefix class="icon card-no-public">person</mat-icon>
              <p>{{ c.clientePrincipal.nombreRazonSocial }}</p>
            </div>

            <div class="paragraph-flex">
              <mat-icon matPrefix class="icon card-no-public">fingerprint</mat-icon>
              <p>{{ c.empresa.rfc }}</p>
            </div>
            <div class="paragraph-flex">
              <mat-icon matPrefix class="icon card-no-public">fingerprint</mat-icon>
              <p>{{ c.clientePrincipal.rfc }}</p>
            </div>

            <div class="paragraph-flex">
              <mat-icon matPrefix class="icon card-no-public">star</mat-icon>
              <p>{{ c.clientePrincipal.tipoPersona }}</p>
            </div>
          </div>

          <mat-divider></mat-divider>

          <div class="grid-3fr-1fr">
            <div class="paragraph-flex">
              <mat-icon matPrefix class="icon">speaker_notes</mat-icon>
              <p>Sector</p>
            </div>
            <div class="paragraph-flex">
              <mat-icon matPrefix class="icon">speaker_notes</mat-icon>
              <p>{{ c.montoAproximadoGanancia.valor | currency }} {{ c.montoAproximadoGanancia.moneda }}</p>
            </div>
            <p>{{ c.sector.valor }}</p>
          </div>

          <mat-divider></mat-divider>

          <div class="grid-3fr-1fr">
            <div class="paragraph-flex double-row">
              <mat-icon matPrefix class="icon card-no-public">public</mat-icon>
              <p>
                {{ c.ubicacion.pais ? 'En el extranjero' : 'En México' }}
              </p>
            </div>
            <div class="paragraph-flex double-row">
              <mat-icon matPrefix class="icon card-no-public">image_aspect_ratio</mat-icon>
              <p>
                {{ c.ubicacion.pais ? c.ubicacion.pais : c.ubicacion.entidadFederativa.valor }}
              </p>
            </div>
          </div>
          <mat-divider></mat-divider>
        </mat-card-content>
        <mat-card-actions>
          <button mat-button color="primary" (click)="editItem(i)">Modificar</button>
          <button mat-button color="primary" (click)="removeItem(i)">Eliminar</button>
        </mat-card-actions>
      </mat-card>

      <div *ngIf="!editMode && aclaracionesText">
        <div class="paragraph-flex aclaraciones">
          <mat-icon matPrefix class="icon card-no-public">insert_comment</mat-icon>
          <p>Aclaraciones / Observaciones</p>
        </div>
        <p class="vertical-margin-25">{{ aclaracionesText }}</p>
      </div>

      <div class="boton-agregar" *ngIf="(cliente.length && !editMode) || clientesPrincipalesForm.value.ninguno">
        <button mat-raised-button color="primary" (click)="addItem()">AGREGAR CLIENTE</button>
      </div>
    </div>

    <!-- Formulario -->
    <form (ngSubmit)="saveItem()" [formGroup]="clientesPrincipalesForm" [ngClass]="{ hidden: !editMode }">
      <!-- Agregar -->
      <div class="padding-agregar" formGroupName="cliente">
        <mat-divider></mat-divider>
        <p>Agregar cliente principal</p>
        <!-- Datos -->

        <div class="grid-3-col grid-margin">
          <mat-form-field appearance="fill">
            <mat-label>Tipo de Operacion</mat-label>
            <mat-icon matPrefix>beenhere</mat-icon>
            <mat-select formControlName="tipoOperacion" required>
              <mat-option *ngFor="let tipoOperacion of tipoOperacionCatalogo" [value]="tipoOperacion">
                {{ tipoOperacion | replace: '_':' ' }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <div class="grid-2-col grid-margin">
          <mat-radio-group
            matTooltip="{{ tooltipData.realizaActividadLucrativaIndependiente }}"
            class="checkbox"
            formControlName="realizaActividadLucrativa"
          >
            <label class="question"
              >¿Realiza alguna actividad lucrativa independiente al empleo, cargo o comisión?</label
            >
            <mat-radio-button [value]="true">Si</mat-radio-button>
            <mat-radio-button [value]="false">No</mat-radio-button>
          </mat-radio-group>
          <mat-form-field matTooltip="{{ tooltipData.quien }}" appearance="fill">
            <mat-label>¿Quién realiza la actividad?</mat-label>
            <mat-icon matPrefix>beenhere</mat-icon>
            <mat-select formControlName="tipoRelacion" required>
              <mat-option *ngFor="let relacion of relacionCatalogo" [value]="relacion">{{
                relacion | replace: '_':' '
              }}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <div class="grid-2-col grid-margin" formGroupName="empresa">
          <mat-form-field matTooltip="{{ tooltipData.nombreEmpresa }}" appearance="fill">
            <mat-label>Nombre de la empresa o servicio que proporciona</mat-label>
            <mat-icon matPrefix>person</mat-icon>
            <input
              [errorStateMatcher]="errorMatcher"
              matInput
              type="text"
              formControlName="nombreEmpresaServicio"
              required
            />
          </mat-form-field>
          <mat-form-field matTooltip="{{ tooltipData.rfc }}" appearance="fill">
            <mat-label>RFC</mat-label>
            <mat-icon matPrefix>beenhere</mat-icon>
            <input
              [errorStateMatcher]="errorMatcher"
              matInput
              #input_rfc_empresa
              type="text"
              maxlength="12"
              formControlName="rfc"
              required
            />
            <mat-hint align="end">{{ input_rfc_empresa.value?.length || 0 }}/12</mat-hint>
          </mat-form-field>
        </div>

        <div class="grid-3-col grid-margin" formGroupName="clientePrincipal">
          <mat-radio-group
            matTooltip="{{ tooltipData.clientePrincipal }}"
            class="checkbox"
            formControlName="tipoPersona"
          >
            <label class="question">Cliente principal</label>
            <mat-radio-button value="FISICA"><span class="no-public-inline"></span>Persona Física</mat-radio-button>
            <mat-radio-button value="MORAL">Persona Moral</mat-radio-button>
          </mat-radio-group>
          <mat-form-field matTooltip="{{ tooltipData.nombreRazonSocialClientePrincipal }}" appearance="fill">
            <mat-label>Nombre o razón social del cliente principal</mat-label>
            <mat-icon matPrefix class="no-public">person</mat-icon>
            <input
              [errorStateMatcher]="errorMatcher"
              matInput
              type="text"
              formControlName="nombreRazonSocial"
              required
            />
          </mat-form-field>
          <mat-form-field matTooltip="{{ tooltipData.rfcClientePrincipal }}" appearance="fill">
            <mat-label>RFC</mat-label>
            <mat-icon matPrefix class="no-public">fingerprint</mat-icon>
            <input
              [errorStateMatcher]="errorMatcher"
              matInput
              #input_rfc
              type="text"
              maxlength="13"
              formControlName="rfc"
              required
            />
            <mat-hint align="end">{{ input_rfc.value?.length || 0 }}/12-13</mat-hint>
          </mat-form-field>
        </div>

        <div class="grid-2-col grid-margin">
          <mat-form-field matTooltip="{{ tooltipData.sectorProductivo }}" appearance="fill">
            <mat-label>Sector al que pertenece</mat-label>
            <mat-icon matPrefix>person</mat-icon>
            <mat-select formControlName="sector" required>
              <mat-option *ngFor="let sector of sectorCatalogo" [value]="sector">
                {{ sector.valor }}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field
            appearance="fill"
            [ngClass]="{ hidden: clientesPrincipalesForm.value.cliente.sector?.clave !== 'OTRO' }"
          >
            <mat-label>Especifique</mat-label>
            <mat-icon matPrefix class="no-public">person</mat-icon>
            <input [errorStateMatcher]="errorMatcher" #otroSector matInput type="text" required />
          </mat-form-field>

          <div class="grid-2-col" formGroupName="montoAproximadoGanancia">
            <mat-form-field matTooltip="{{ tooltipData.montoAproximadoBeneficio }}" appearance="fill">
              <mat-label>Monto Aproximado del beneficio o ganancia mensual que obtiene del cliente principal</mat-label>
              <mat-icon matPrefix>account_balance_wallet</mat-icon>
              <input
                [errorStateMatcher]="errorMatcher"
                matInput
                type="number"
                formControlName="valor"
                min="0"
                required
              />
            </mat-form-field>
            <mat-form-field appearance="fill">
              <mat-label>Tipo de moneda</mat-label>
              <mat-icon matPrefix>beenhere</mat-icon>
              <mat-select formControlName="moneda" required>
                <mat-option *ngFor="let m of monedasCatalogo" [value]="m.clave">
                  {{ m.clave }} - {{ m.valor }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>

        <div class="grid-2-col grid-margin" formGroupName="ubicacion">
          <mat-form-field matTooltip="{{ tooltipData.lugarUbicacion }}" appearance="fill">
            <mat-label>Lugar donde se ubica</mat-label>
            <mat-icon matPrefix>public</mat-icon>
            <mat-select #locationSelect (selectionChange)="locationChanged($event.value)" required>
              <mat-option value="MEXICO"> MÉXICO </mat-option>
              <mat-option value="EXTRANJERO"> EN EL EXTRANJERO </mat-option>
            </mat-select>
          </mat-form-field>
          <!-- México -->
          <mat-form-field appearance="fill" *ngIf="this.tipoDomicilio === 'MEXICO'">
            <mat-label>Entidad Federativa</mat-label>
            <mat-icon matPrefix>beenhere</mat-icon>
            <mat-select formControlName="entidadFederativa" required>
              <mat-option *ngFor="let estados of estadosCatalogo" [value]="estados">
                {{ estados.valor }}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <!-- Extranjero -->
          <mat-form-field appearance="fill" *ngIf="this.tipoDomicilio === 'EXTRANJERO'">
            <mat-label>País </mat-label>
            <mat-icon matPrefix>beenhere</mat-icon>
            <mat-select formControlName="pais" required>
              <mat-option *ngFor="let opt of paisesCatalogo" [value]="opt.clave">
                {{ opt.valor }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>

      <div class="boton" *ngIf="!aclaraciones">
        <button mat-raised-button class="text-primary" (click)="toggleAclaraciones(true)">
          AGREGAR ACLARACIONES / OBSERVACIONES
        </button>
      </div>
      <div *ngIf="aclaraciones">
        <div class="flex grid-margin">
          <p>Aclaraciones / Observaciones</p>
          <button mat-raised-button color="primary" (click)="toggleAclaraciones(false)">CANCELAR</button>
        </div>

        <mat-form-field matTooltip="{{ tooltipData.aclaracionesObservaciones }}" appearance="fill" class="grid-margin">
          <mat-icon matPrefix class="aclaraciones-no-public">speaker_notes</mat-icon>
          <textarea
            maxlength="500"
            [errorStateMatcher]="errorMatcher"
            matInput
            formControlName="aclaracionesObservaciones"
            rows="10"
          ></textarea>
        </mat-form-field>
      </div>

      <mat-divider></mat-divider>

      <div class="boton text-right">
        <app-loader class="inline-loader" [isLoading]="isLoading"></app-loader>
        <button mat-raised-button color="primary" type="button" *ngIf="editMode" (click)="cancelEditMode()">
          CANCELAR
        </button>
        <button mat-raised-button color="primary" type="button" (click)="clientesPrincipalesForm.reset()">
          LIMPIAR FORMULARIO
        </button>
        <button
          mat-raised-button
          color="primary"
          [disabled]="clientesPrincipalesForm.invalid || !inputsAreValid() || isLoading"
        >
          GUARDAR CAMBIOS
        </button>
      </div>
    </form>

    <mat-divider></mat-divider>

    <div class="boton center">
      <button mat-raised-button color="primary" (click)="formHasChanges()">
        CONTINUAR 6 DE 7
        <mat-icon matSofix>arrow_forward</mat-icon>
      </button>
    </div>

    <section-footer [declaracionId]="declaracionId"></section-footer>
  </mat-card>
</div>
