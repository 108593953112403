<div fxFill fxLayout="column" fxLayoutAlign="center">
  <app-header></app-header>
  <mat-toolbar class="background-white title-toolbar">
    <label class="step-title">Iniciar sesión</label>
  </mat-toolbar>
  <mat-divider></mat-divider>
  <div class="login-container" fxLayout="row" fxLayoutAlign="center center">
    <mat-card fxFlex="90%" fxFlex.sm="66%" fxFlex.md="50%" fxFlex.gt-md="33%" class="login-box">
      <form (ngSubmit)="login()" [formGroup]="loginForm" novalidate>
        <div class="login-error" [hidden]="!error || isLoading" translate>Username or password incorrect.</div>
        <br />
        <!--<div class="login-fields" fxLayout="column">-->
        <div class="login-fields">
          <mat-form-field [hideRequiredMarker]="true" appearance="fill">
            <mat-label translate>Email</mat-label>
            <mat-icon matPrefix>mail</mat-icon>
            <input
              type="text"
              matInput
              formControlName="username"
              autocomplete="username"
              [placeholder]="'Username' | translate"
              maxlength="255"
              required
            />
            <mat-error *ngIf="loginForm.controls.username.invalid && loginForm.controls.username.touched">
              <span translate>Username is required</span>
            </mat-error>
          </mat-form-field>
          <br />
          <mat-form-field [hideRequiredMarker]="true" appearance="fill">
            <mat-label translate>Password</mat-label>
            <mat-icon matPrefix>lock</mat-icon>
            <input
              type="password"
              matInput
              formControlName="password"
              autocomplete="current-password"
              [placeholder]="'Password' | translate"
              maxlength="20"
              required
            />
            <mat-error *ngIf="loginForm.controls.password.invalid && loginForm.controls.password.touched">
              <span translate>Password is required</span>
            </mat-error>
          </mat-form-field>
          <button mat-button color="primary" type="button" routerLink="/recuperar-contrasena">
            <span translate>Forgot your password?</span>
          </button>
          <br />
          <button
            mat-raised-button
            class="login-btn text-uppercase"
            color="primary"
            type="submit"
            [disabled]="loginForm.invalid || isLoading"
          >
            <app-loader class="inline-loader" [isLoading]="isLoading"></app-loader>
            <span translate>Login</span>
          </button>
        </div>
      </form>
    </mat-card>
  </div>
  <app-footer></app-footer>
</div>
