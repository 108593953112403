<div class="section-main-container">
  <mat-card class="form-card">
    <section-header
      section="I. Declaración de situación patrimonial"
      [simplificada]="declaracionSimplificada"
      progress="22.5"
      type="{{ tipoDeclaracion }}"
    ></section-header>

    <mat-divider></mat-divider>

    <mat-toolbar class="background-white">
      <label class="step-title"> 5. Experiencia laboral (últimos cinco empleos)</label>
    </mat-toolbar>

    <mat-divider></mat-divider>

    <p class="vertical-margin-25">
      Todos los campos * son obligatorios. Los datos de terceros, siempre y cuando sean personas físicas, y los campos
      resaltados <span class="no-public-inline"></span> no serán públicos. Proporcionar información correspondiente a
      los últimos cinco empleos de experiencia laboral. Se llenará la información relativa a todos los encargos, empleos
      o comisiones públicos o privados que haya tenido.
    </p>

    <!-- Botones iniciales -->
    <div class="" *ngIf="!experiencia?.length && !editMode && !experienciaLaboralForm.value.ninguno">
      <mat-grid-list cols="2" rowHeight="150px">
        <mat-grid-tile colspan="1">
          <div class="boton-agregar">
            <button mat-raised-button color="primary" (click)="noExperience()">Ninguno</button>
          </div>
        </mat-grid-tile>
        <mat-grid-tile colspan="1">
          <div class="boton-agregar">
            <button mat-raised-button color="primary" (click)="addItem()">Agregar empleo</button>
          </div>
        </mat-grid-tile>
      </mat-grid-list>
    </div>

    <!-- card de información -->
    <div>
      <mat-card class="info text-center" *ngIf="experienciaLaboralForm.value.ninguno">
        <h3 class="vertical-padding-50">Ningún empleo</h3>
      </mat-card>

      <mat-card class="info" *ngFor="let e of experiencia; let i = index">
        <mat-card-content class="">
          <!-- Publico -->
          <div class="grid-3fr-1fr" *ngIf="e.ambitoSector.clave === 'PUB'">
            <div class="paragraph-flex">
              <mat-icon matPrefix class="icon">aspect_ratio</mat-icon>
              <p>
                Sector: <span class="resaltar">{{ e.ambitoSector?.valor }}</span>
              </p>
            </div>
            <div class="paragraph-flex">
              <mat-icon matPrefix class="icon">start</mat-icon>
              <p>
                Nivel: <span class="resaltar">{{ e.nivelOrdenGobierno | replace: '_':' ' }}</span>
              </p>
            </div>

            <div class="paragraph-flex">
              <mat-icon matPrefix class="icon">dns</mat-icon>
              <p>
                Ámbito: <span class="resaltar">{{ e.ambitoPublico | replace: '_':' ' }}</span>
              </p>
            </div>
            <div class="paragraph-flex double-row">
              <mat-icon matPrefix class="icon">wb_iridescent</mat-icon>
              <p>
                Área de Adscripción: <br />
                <span class="aumento-adscripcion">{{ e.areaAdscripcion }}</span>
              </p>
            </div>
            <div class="paragraph-flex">
              <mat-icon matPrefix class="icon">speaker_notes</mat-icon>
              <p>Nombre del Ente Público: {{ e.nombreEntePublico }}</p>
            </div>
            <div class="size-20 paragraph-flex">
              <mat-icon matPrefix class="icon">work</mat-icon>
              <p>
                <span class="negritas">{{ e.empleoCargoComision }}</span>
              </p>
            </div>
          </div>
          <!-- Privado u Otro -->
          <div class="grid-3fr-1fr" *ngIf="e.ambitoSector.clave === 'PRV' || e.ambitoSector.clave === 'OTR'">
            <div class="paragraph-flex">
              <mat-icon matPrefix class="icon">aspect_ratio</mat-icon>
              <p>
                Sector: <span class="resaltar">{{ e.ambitoSector?.valor }}</span>
              </p>
            </div>
            <div class="paragraph-flex"></div>

            <div class="paragraph-flex">
              <mat-icon matPrefix class="icon">speaker_notes</mat-icon>
              <p>Nombre de la empresa, sociedad o asociación: {{ e.nombreEmpresaSociedadAsociacion }}</p>
            </div>
            <div class="paragraph-flex">
              <mat-icon matPrefix class="icon">fingerprint</mat-icon>
              <p>RFC: {{ e.rfc || 'SIN INFORMACIÓN' }}</p>
            </div>

            <div class="paragraph-flex">
              <mat-icon matPrefix class="icon">wb_iridescent</mat-icon>
              <p>Área: {{ e.area }}</p>
            </div>
            <div class="size-20 paragraph-flex">
              <mat-icon matPrefix class="icon">work</mat-icon>
              <p>
                <span class="negritas">{{ e.puesto }}</span>
              </p>
            </div>

            <div class="paragraph-flex">
              <mat-icon matPrefix class="icon">assistant</mat-icon>
              <p>
                Sector: <span class="resaltar">{{ e.sector?.valor }}</span>
              </p>
            </div>
          </div>
          <!-- Funcion principal -->
          <mat-divider></mat-divider>
          <div class="padding-card-section" *ngIf="e.ambitoSector.clave == 'PUB'">
            <div class="paragraph-flex">
              <mat-icon matPrefix class="icon">speaker_notes</mat-icon>
              <p>Especifique función principal</p>
            </div>
            <p>
              {{ e.funcionPrincipal }}
            </p>
          </div>

          <mat-divider></mat-divider>

          <div class="card-grid-3-col">
            <div class="paragraph-flex">
              <mat-icon matPrefix class="icon">date_range</mat-icon>
              <p>Fecha de ingreso</p>
            </div>
            <div class="paragraph-flex">
              <mat-icon matPrefix class="icon">date_range</mat-icon>
              <p>Fecha de egreso</p>
            </div>
            <div class="">
              <p>Lugar donde se ubica</p>
            </div>
            <div class="">
              <p>{{ e.fechaIngreso | date: 'dd/MM/yyyy' }}</p>
            </div>
            <div class="">
              <p>{{ e.fechaEgreso | date: 'dd/MM/yyyy' }}</p>
            </div>
            <div class="paragraph-flex">
              <mat-icon matPrefix class="icon">public</mat-icon>
              <p>{{ e.ubicacion === 'MX' ? 'EN MÉXICO' : 'EN EL EXTRANJERO' }}</p>
            </div>
          </div>
          <mat-divider></mat-divider>
        </mat-card-content>
        <mat-card-actions>
          <button mat-button color="primary" (click)="editItem(i)">Modificar</button>
          <button mat-button color="primary" (click)="removeItem(i)">Eliminar</button>
        </mat-card-actions>
      </mat-card>

      <div *ngIf="!editMode && aclaracionesText">
        <div class="paragraph-flex aclaraciones">
          <mat-icon matPrefix class="icon card-no-public">insert_comment</mat-icon>
          <p>Aclaraciones / Observaciones</p>
        </div>
        <p class="vertical-margin-25">{{ aclaracionesText }}</p>
      </div>

      <div
        class="boton-agregar"
        *ngIf="(experiencia.length > 0 && experiencia.length < 5 && !editMode) || experienciaLaboralForm.value.ninguno"
      >
        <button mat-raised-button color="primary" (click)="addItem()">AGREGAR EXPERIENCIA</button>
      </div>
    </div>

    <form (ngSubmit)="saveItem()" [formGroup]="experienciaLaboralForm" [ngClass]="{ hidden: !editMode }">
      <!-- Agregar empleo, cargo o comisión -->
      <div formGroupName="experiencia">
        <p class="vertical-margin-25">Agregar empleo, cargo o comisión</p>
        <div class="grid-2-col grid-margin">
          <mat-form-field matTooltip="{{ tooltipData.ambitoSector }}" appearance="fill">
            <mat-label>Ámbito/Sector en el que laboraste</mat-label>
            <mat-icon matPrefix>start</mat-icon>
            <mat-select formControlName="ambitoSector" required>
              <mat-option *ngFor="let ambito of ambitoSectorCatalogo" [value]="ambito">
                {{ ambito.valor }}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field
            appearance="fill"
            [ngClass]="{ hidden: experienciaLaboralForm.value.experiencia.ambitoSector?.clave !== 'OTR' }"
          >
            <mat-label>Especifique</mat-label>
            <mat-icon matPrefix>start</mat-icon>
            <input [errorStateMatcher]="errorMatcher" #otroAmbitoSector matInput type="text" required />
          </mat-form-field>
        </div>

        <!-- Publico -->
        <div [ngClass]="{ hidden: experienciaLaboralForm.value.experiencia.ambitoSector?.clave !== 'PUB' }">
          <div class="grid-2-col grid-margin">
            <mat-form-field appearance="fill">
              <mat-label>Nivel/Orden de Gobierno </mat-label>
              <mat-icon matPrefix>aspect_ratio</mat-icon>
              <mat-select
                matTooltip="{{ tooltipData.sectorPublico.nivelOrdenGobierno }}"
                formControlName="nivelOrdenGobierno"
                required
              >
                <mat-option *ngFor="let orden of nivelOrdenGobiernoCatalogo" value="{{ orden }}">
                  {{ orden | replace: '_':' ' }}
                </mat-option>
              </mat-select>
            </mat-form-field>
            <mat-form-field matTooltip="{{ tooltipData.sectorPublico.ambitoPublico }}" appearance="fill">
              <mat-label>Ámbito Público</mat-label>
              <mat-icon matPrefix>meeting_room</mat-icon>
              <mat-select formControlName="ambitoPublico" required>
                <mat-option *ngFor="let publico of ambitoPublicoCatalogo" value="{{ publico }}">
                  {{ publico | replace: '_':' ' }}
                </mat-option>
              </mat-select>
            </mat-form-field>
            <mat-form-field matTooltip="{{ tooltipData.sectorPublico.nombreEntePublico }}" appearance="fill">
              <mat-label>Nombre del ente Público</mat-label>
              <mat-icon matPrefix>dns</mat-icon>
              <input
                [errorStateMatcher]="errorMatcher"
                matInput
                type="text"
                formControlName="nombreEntePublico"
                required
              />
            </mat-form-field>
            <mat-form-field matTooltip="{{ tooltipData.sectorPublico.areaAdscripcion }}" appearance="fill">
              <mat-label>Área de Adscripción</mat-label>
              <mat-icon matPrefix>wb_iridescent</mat-icon>
              <input
                [errorStateMatcher]="errorMatcher"
                matInput
                type="text"
                formControlName="areaAdscripcion"
                required
              />
            </mat-form-field>
            <mat-form-field matTooltip="{{ tooltipData.sectorPublico.empleoCargoComision }}" appearance="fill">
              <mat-label>Empleo, cargo o comisión</mat-label>
              <mat-icon matPrefix>work</mat-icon>
              <input
                [errorStateMatcher]="errorMatcher"
                matInput
                type="text"
                formControlName="empleoCargoComision"
                required
              />
            </mat-form-field>
            <mat-form-field matTooltip="{{ tooltipData.sectorPublico.especifiqueFuncionPrincipal }}" appearance="fill">
              <mat-label>Especifique función principal</mat-label>
              <mat-icon matPrefix>speaker_notes</mat-icon>
              <input
                [errorStateMatcher]="errorMatcher"
                matInput
                type="text"
                formControlName="funcionPrincipal"
                required
              />
            </mat-form-field>
          </div>
        </div>

        <!-- otro y privado -->
        <div
          class="grid-2-col grid-margin"
          [ngClass]="{
            hidden: !(
              experienciaLaboralForm.value.experiencia.ambitoSector?.clave === 'PRV' ||
              experienciaLaboralForm.value.experiencia.ambitoSector?.clave === 'OTR'
            )
          }"
        >
          <mat-form-field
            matTooltip="{{ tooltipData.sectorPrivado.nombreEmpresSociedadAsociacion }}"
            appearance="fill"
            required
          >
            <mat-label>Nombre de la empresa, sociedad o asociación</mat-label>
            <mat-icon matPrefix>speaker_notes</mat-icon>
            <input
              [errorStateMatcher]="errorMatcher"
              matInput
              type="text"
              formControlName="nombreEmpresaSociedadAsociacion"
              required
            />
          </mat-form-field>
          <mat-form-field matTooltip="{{ tooltipData.sectorPrivado.rfc }}" appearance="fill">
            <mat-label>RFC</mat-label>
            <mat-icon matPrefix>fingerprint</mat-icon>
            <input
              [errorStateMatcher]="errorMatcher"
              matInput
              #input_rfc
              type="text"
              formControlName="rfc"
              maxlength="12"
            />
            <mat-hint align="end">{{ input_rfc.value?.length || 0 }}/12</mat-hint>
          </mat-form-field>
          <mat-form-field matTooltip="{{ tooltipData.sectorPrivado.area }}" appearance="fill">
            <mat-label>Área</mat-label>
            <mat-icon matPrefix>wb_iridescent</mat-icon>
            <input [errorStateMatcher]="errorMatcher" matInput type="text" formControlName="area" required />
          </mat-form-field>
          <mat-form-field matTooltip="{{ tooltipData.sectorPrivado.puesto }}" appearance="fill">
            <mat-label>Puesto</mat-label>
            <mat-icon matPrefix>work</mat-icon>
            <input [errorStateMatcher]="errorMatcher" matInput type="text" formControlName="puesto" required />
          </mat-form-field>
          <mat-form-field matTooltip="{{ tooltipData.sectorPrivado.sectorPertenece }}" appearance="fill">
            <mat-label>Sector al que pertenece</mat-label>
            <mat-icon matPrefix>assistant</mat-icon>
            <mat-select formControlName="sector" required>
              <mat-option *ngFor="let sector of sectorCatalogo" [value]="sector">
                {{ sector.valor }}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field
            appearance="fill"
            [ngClass]="{ hidden: experienciaLaboralForm.value.experiencia.sector?.clave !== 'OTRO' }"
          >
            <mat-label>Especifique</mat-label>
            <mat-icon matPrefix>assistant</mat-icon>
            <input [errorStateMatcher]="errorMatcher" #otroSector matInput type="text" required />
          </mat-form-field>
        </div>

        <div class="grid-2-col grid-margin" *ngIf="experienciaLaboralForm.value.experiencia.ambitoSector">
          <mat-form-field matTooltip="{{ tooltipData.fechaIngreso }}" appearance="fill">
            <mat-label>Fecha de Ingreso</mat-label>
            <input
              [errorStateMatcher]="errorMatcher"
              matInput
              formControlName="fechaIngreso"
              [matDatepicker]="pickerOtroIngreso"
              required
            />
            <mat-hint>dd/mm/yyyy</mat-hint>
            <mat-datepicker-toggle matPrefix [for]="pickerOtroIngreso"></mat-datepicker-toggle>
            <mat-datepicker #pickerOtroIngreso></mat-datepicker>
          </mat-form-field>
          <mat-form-field matTooltip="{{ tooltipData.fechaEgreso }}" appearance="fill">
            <mat-label>Fecha de Egreso</mat-label>
            <input
              [errorStateMatcher]="errorMatcher"
              matInput
              formControlName="fechaEgreso"
              [matDatepicker]="pickerOtroEgreso"
              required
            />
            <mat-hint>dd/mm/yyyy</mat-hint>
            <mat-datepicker-toggle matPrefix [for]="pickerOtroEgreso"></mat-datepicker-toggle>
            <mat-datepicker #pickerOtroEgreso></mat-datepicker>
          </mat-form-field>

          <mat-form-field matTooltip="{{ tooltipData.lugarDondeSeUbica }}" appearance="fill">
            <mat-label>Lugar donde se ubica</mat-label>
            <mat-icon matPrefix>public</mat-icon>
            <mat-select formControlName="ubicacion" required>
              <mat-option value="{{ extranjeroCatalogo[0] }}"> EN MÉXICO </mat-option>
              <mat-option value="{{ extranjeroCatalogo[1] }}"> EN EL EXTRANJERO </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>

      <mat-grid-list *ngIf="!aclaraciones" cols="15" rowHeight="100px">
        <mat-grid-tile class="content-left" colspan="15">
          <button mat-raised-button type="button" class="text-primary" (click)="toggleAclaraciones(true)">
            AGREGAR ACLARACIONES / OBSERVACIONES
          </button>
        </mat-grid-tile>
      </mat-grid-list>

      <div *ngIf="aclaraciones">
        <div class="flex grid-margin">
          <p>Aclaraciones / Observaciones</p>
          <button mat-raised-button color="primary" (click)="toggleAclaraciones(false)">CANCELAR</button>
        </div>

        <mat-form-field appearance="fill" class="grid-margin">
          <mat-icon matPrefix class="aclaraciones-no-public">speaker_notes</mat-icon>
          <textarea
            maxlength="500"
            [errorStateMatcher]="errorMatcher"
            matInput
            formControlName="aclaracionesObservaciones"
            rows="10"
          ></textarea>
        </mat-form-field>
      </div>

      <div class="boton text-right">
        <app-loader class="inline-loader" [isLoading]="isLoading"></app-loader>
        <button mat-raised-button color="primary" type="button" *ngIf="editMode" (click)="cancelEditMode()">
          CANCELAR
        </button>
        <button mat-raised-button color="primary" type="button" (click)="experienciaLaboralForm.reset()">
          LIMPIAR FORMULARIO
        </button>
        <button
          mat-raised-button
          color="primary"
          [disabled]="experienciaLaboralForm.invalid || !inputsAreValid() || isLoading"
        >
          GUARDAR CAMBIOS
        </button>
      </div>
    </form>

    <mat-divider></mat-divider>

    <div class="boton center">
      <button *ngIf="!declaracionSimplificada" mat-raised-button color="primary" (click)="formHasChanges()">
        CONTINUAR 6 DE 15
        <mat-icon matSofix>arrow_forward</mat-icon>
      </button>

      <button *ngIf="declaracionSimplificada" mat-raised-button color="primary" (click)="formHasChanges()">
        CONTINUAR 6 DE 7
        <mat-icon matSofix>arrow_forward</mat-icon>
      </button>
    </div>

    <section-footer [declaracionId]="declaracionId"></section-footer>
  </mat-card>
</div>
