import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-tratamiento-de-informacion',
  templateUrl: './tratamiento-de-informacion.component.html',
  styleUrls: ['./tratamiento-de-informacion.component.scss', '../sections.scss'],
})
export class TratamientoDeInformacionComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
