<div class="section-main-container">
  <mat-card class="form-card">
    <section-header
      section="I. Declaración de situación patrimonial"
      progress="63"
      type="{{ tipoDeclaracion }}"
    ></section-header>

    <mat-divider></mat-divider>

    <mat-toolbar class="background-white">
      <label class="step-title">
        14. Adeudos/Pasivos
        <span *ngIf="tipoDeclaracion === 'inicial'"> (situación actual)</span>
        <span *ngIf="tipoDeclaracion === 'modificacion'">
          (entre el 1 de enero y 31 de diciembre del año inmediato anterior)</span
        >
        <span *ngIf="tipoDeclaracion === 'conclusion'"> (del año en curso a la fecha de conclusión del empleo)</span>
      </label>
    </mat-toolbar>

    <mat-divider></mat-divider>

    <p>
      Todos los campos * son obligatorios. Todos los datos de adeudos/pasivos a nombre de la
      <span class="negritas">pareja, dependientes económicos y/o terceros</span> o que sean en copropiedad con el
      declarante no seran públicos y los campos resaltados <span class="no-public-inline"></span> no serán públicos.

      <br />
      <span *ngIf="tipoDeclaracion === 'inicial'">
        Para la declaración de inicio reportar la situación de adeudos/pasivos a la fecha de ingreso al empleo, cargo o
        comisión.
      </span>
      <span *ngIf="tipoDeclaracion === 'modificacion'">
        Para la declaración de modificación reportar la situación de adeudos/pasivos del año inmediato anterior.
      </span>
      <span *ngIf="tipoDeclaracion === 'conclusion'">
        Para la declaración de conclusión reportar la situación de adeudos/pasivos a la fecha de conclusión del empleo,
        cargo o comisión.
      </span>
      <br />
      El Declarante reportará la información sobre cualquier tipo de deuda y por cada una de ellas.
    </p>

    <!-- Botones iniciales -->
    <div *ngIf="!adeudo?.length && !editMode && !adeudosPasivosForm.value.ninguno">
      <mat-grid-list cols="2" rowHeight="150px">
        <mat-grid-tile colspan="1">
          <div class="boton-agregar">
            <button mat-raised-button color="primary" (click)="noDebts()">Ninguno</button>
          </div>
        </mat-grid-tile>
        <mat-grid-tile colspan="1">
          <div class="boton-agregar">
            <button mat-raised-button color="primary" (click)="addItem()">Agregar Adeudos/Pasivos</button>
          </div>
        </mat-grid-tile>
      </mat-grid-list>
    </div>

    <!-- card de información -->
    <div class="">
      <mat-card class="info" *ngIf="adeudosPasivosForm.value.ninguno">
        <p>Ningún adeudo/pasivo</p>
      </mat-card>

      <mat-card class="info" *ngFor="let p of adeudo; let i = index">
        <mat-card-content class="">
          <div class="grid-3fr-1fr">
            <div class="paragraph-flex size-20">
              <mat-icon matPrefix class="icon">aspect_ratio</mat-icon>
              <p>
                <span class="negritas">{{ p.tipoAdeudo.valor }}</span>
              </p>
            </div>
            <div class="paragraph-flex">
              <mat-icon matPrefix class="icon card-no-public">person</mat-icon>
              <p>
                Titular: <span class="resaltar" *ngFor="let dato of p.titular">{{ dato.valor }}</span>
              </p>
            </div>
          </div>

          <!-- Terceros -->
          <mat-divider></mat-divider>

          <p>Nombre del Tercero o Terceros</p>
          <div class="card-grid-3-col" *ngFor="let dato of p.tercero">
            <div class="paragraph-flex">
              <mat-icon matPrefix class="icon">person</mat-icon>
              <p>
                {{ dato.nombreRazonSocial }}
              </p>
            </div>
            <div class="paragraph-flex">
              <mat-icon matPrefix class="icon">fingerprint</mat-icon>
              <p>
                {{ dato.rfc }}
              </p>
            </div>
            <div class="paragraph-flex">
              <mat-icon matPrefix class="icon card-no-public">person</mat-icon>
              <p>
                {{ dato.tipoPersona }}
              </p>
            </div>
          </div>

          <mat-divider></mat-divider>

          <p>Otorgante del crédito</p>
          <div class="card-grid-3-col">
            <div class="paragraph-flex">
              <mat-icon matPrefix class="icon card-no-public">person</mat-icon>
              <p>
                {{ p.otorganteCredito.nombreInstitucion }}
              </p>
            </div>
            <div class="paragraph-flex">
              <mat-icon matPrefix class="icon card-no-public">fingerprint</mat-icon>
              <p>
                {{ p.otorganteCredito.rfc }}
              </p>
            </div>
            <div class="paragraph-flex">
              <mat-icon matPrefix class="icon card-no-public">person</mat-icon>
              <p>
                {{ p.otorganteCredito.tipoPersona }}
              </p>
            </div>
          </div>

          <mat-divider></mat-divider>

          <div class="grid-3fr-1fr">
            <div class="paragraph-flex">
              <mat-icon matPrefix class="icon card-no-public">image_aspect_ratio</mat-icon>
              <p>
                Número de Cuenta: <span class="resaltar">{{ p.numeroCuentaContrato }}</span>
              </p>
            </div>
            <div class="paragraph-flex">
              <mat-icon matPrefix class="icon">credit_card</mat-icon>
              <p>
                Monto original:
                <span class="resaltar">{{ p.montoOriginal.valor | currency }} {{ p.montoOriginal.moneda }} </span>
              </p>
            </div>
            <div class="paragraph-flex">
              <mat-icon matPrefix class="icon">date_range</mat-icon>
              <p>
                Fecha de adquisición del adeudo:
                <span class="resaltar">{{ p.fechaAdquisicion | date: 'dd/MM/yyyy' }}</span>
              </p>
            </div>
            <div class="paragraph-flex">
              <mat-icon matPrefix class="icon card-no-public">account_balance_wallet</mat-icon>
              <p>
                Saldo Indoluto:
                <span class="resaltar">
                  {{ p.saldoInsolutoSituacionActual.valor | currency }} {{ p.saldoInsolutoSituacionActual.moneda }}
                </span>
              </p>
            </div>
          </div>

          <mat-divider></mat-divider>

          <p>Ubicación dónde se localiza la inversión, cuenta bancaria u otro tipo de valores/activos</p>
          <div class="card-grid-3-col">
            <div class="paragraph-flex">
              <mat-icon matPrefix class="icon card-no-public">public</mat-icon>
              <p *ngIf="p.localizacionAdeudo">En el Extranjero</p>
              <p *ngIf="!p.localizacionAdeudo">En México</p>
            </div>
            <div></div>
            <div class="paragraph-flex" *ngIf="p.localizacionAdeudo">
              <mat-icon matPrefix class="icon card-no-public">public</mat-icon>
              <p>
                {{ p.localizacionAdeudo?.pais }}
              </p>
            </div>
          </div>

          <!--           <mat-divider></mat-divider>

          <div class="padding-card-section">
            <div class="paragraph-flex aclaraciones">
              <mat-icon matPrefix class="icon">speaker_notes</mat-icon>
              <p>Aclaraciones/Observaciones</p>
            </div>
            <p>
              {{ p.aclaracionesObservaciones }}
            </p>
          </div> -->

          <mat-divider></mat-divider>
        </mat-card-content>
        <mat-card-actions>
          <button mat-button color="primary" (click)="editItem(i)">Modificar</button>
          <button mat-button color="primary" (click)="removeItem(i)">Eliminar</button>
        </mat-card-actions>
      </mat-card>

      <div class="boton-agregar" *ngIf="(adeudo.length && !editMode) || adeudosPasivosForm.value.ninguno">
        <button mat-raised-button color="primary" (click)="addItem()">Agregar Pasivo</button>
      </div>
    </div>

    <!-- Formulario -->
    <form (ngSubmit)="saveItem()" [formGroup]="adeudosPasivosForm" *ngIf="editMode">
      <!-- Agregar -->
      <div class="padding-agregar" formGroupName="adeudo">
        <mat-divider></mat-divider>

        <!-- Datos adeudo -->
        <div class="">
          <h2>Agregar adeudo/pasivo</h2>
          <div class="grid-2-col grid-margin">
            <mat-form-field matTooltip="{{ tooltipData.tipoAdeudo }}" appearance="fill">
              <mat-label>Tipo de adeudo</mat-label>
              <mat-icon matPrefix>aspect_ratio</mat-icon>
              <mat-select formControlName="tipoAdeudo" required>
                <mat-option *ngFor="let tipoAdeudo of tipoAdeudoCatalogo" [value]="tipoAdeudo">
                  {{ tipoAdeudo.valor }}
                </mat-option>
              </mat-select>
            </mat-form-field>
            <mat-form-field appearance="fill" *ngIf="adeudosPasivosForm.value.adeudo.tipoAdeudo?.clave === 'OTRO'">
              <mat-label>Especifique</mat-label>
              <mat-icon matPrefix class="no-public">aspect_ratio</mat-icon>
              <input [errorStateMatcher]="errorMatcher" matInput type="text" required />
            </mat-form-field>
            <mat-form-field matTooltip="{{ tooltipData.titularAdeudo }}" appearance="fill">
              <mat-label>Titular del adeudo</mat-label>
              <mat-icon matPrefix class="no-public">person</mat-icon>
              <mat-select formControlName="titular" required>
                <mat-option *ngFor="let titularBien of titularBienCatalogo" [value]="titularBien">
                  {{ titularBien.valor }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div class="grid-2-col grid-margin">
            <mat-form-field matTooltip="{{ tooltipData.numeroCuentaContrato }}" appearance="fill">
              <mat-label>Número de cuenta o contrato</mat-label>
              <mat-icon matPrefix class="no-public">image_aspect_ratio</mat-icon>
              <input
                [errorStateMatcher]="errorMatcher"
                matInput
                type="text"
                formControlName="numeroCuentaContrato"
                required
              />
            </mat-form-field>
            <mat-form-field matTooltip="{{ tooltipData.fechaAdquisicion }}" appearance="fill">
              <mat-label>Fecha de Adquisición del adeudo/pasivo</mat-label>
              <input
                [errorStateMatcher]="errorMatcher"
                matInput
                [matDatepicker]="pickerAdquisicion"
                formControlName="fechaAdquisicion"
                required
              />
              <mat-hint>dd/mm/yyyy</mat-hint>
              <mat-datepicker-toggle matPrefix [for]="pickerAdquisicion"> </mat-datepicker-toggle>
              <mat-datepicker #pickerAdquisicion></mat-datepicker>
            </mat-form-field>
          </div>

          <div class="grid-2-col grid-margin" formGroupName="montoOriginal">
            <mat-form-field matTooltip="{{ tooltipData.montoOriginal }}" appearance="fill">
              <mat-label>Monto original del adeudo/pasivo</mat-label>
              <mat-icon matPrefix>credit_card</mat-icon>
              <input
                [errorStateMatcher]="errorMatcher"
                matInput
                type="number"
                formControlName="valor"
                min="0"
                required
              />
            </mat-form-field>
            <mat-form-field matTooltip="{{ tooltipData.tipoMoneda }}" appearance="fill">
              <mat-label>Tipo de Moneda</mat-label>
              <mat-icon matPrefix>credit_card</mat-icon>
              <mat-select formControlName="moneda" required>
                <mat-option *ngFor="let m of monedasCatalogo" [value]="m.clave">
                  {{ m.clave }} - {{ m.valor }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <!-- Tercero -->
          <div class="grid-3-col grid-margin" formGroupName="tercero">
            <mat-radio-group matTooltip="{{ tooltipData.tercero }}" class="checkbox" formControlName="tipoPersona">
              <p class="question double-col">Tercero</p>
              <mat-radio-button value="FISICA"><span class="no-public-inline"></span> Persona Física</mat-radio-button>
              <mat-radio-button value="MORAL">Persona Moral</mat-radio-button>
            </mat-radio-group>
            <mat-form-field matTooltip="{{ tooltipData.nombreTercero }}" appearance="fill">
              <mat-label>Nombre del Tercero o Terceros</mat-label>
              <mat-icon matPrefix>group</mat-icon>
              <input [errorStateMatcher]="errorMatcher" matInput type="text" formControlName="nombreRazonSocial" />
            </mat-form-field>
            <mat-form-field matTooltip="{{ tooltipData.rfcTercero }}" appearance="fill">
              <mat-label>RFC</mat-label>
              <mat-icon matPrefix>fingerprint</mat-icon>
              <input
                [errorStateMatcher]="errorMatcher"
                matInput
                #input_rfc_tercero
                formControlName="rfc"
                type="text"
                maxlength="13"
              />
              <mat-hint align="end">{{ input_rfc_tercero.value?.length || 0 }}/13</mat-hint>
            </mat-form-field>
          </div>

          <div class="grid-2-col grid-margin" formGroupName="saldoInsolutoSituacionActual">
            <mat-form-field matTooltip="{{ tooltipData.saldoInsoluto }}" appearance="fill">
              <mat-label>Saldo Insoluto (Situación Actual)</mat-label>
              <mat-icon matPrefix class="no-public">credit_card</mat-icon>
              <input
                [errorStateMatcher]="errorMatcher"
                matInput
                type="number"
                formControlName="valor"
                min="0"
                required
              />
            </mat-form-field>
            <mat-form-field matTooltip="{{ tooltipData.saldoInsoluto }}" appearance="fill">
              <mat-label>Tipo de Moneda</mat-label>
              <mat-icon matPrefix>credit_card</mat-icon>
              <mat-select formControlName="moneda" required>
                <mat-option *ngFor="let m of monedasCatalogo" [value]="m.clave">
                  {{ m.clave }} - {{ m.valor }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div class="grid-3-col grid-margin" formGroupName="otorganteCredito">
            <mat-radio-group
              matTooltip="{{ tooltipData.otorganteCredito }}"
              class="checkbox"
              formControlName="tipoPersona"
            >
              <p class="question double-col">Otorgante del crédito</p>
              <mat-radio-button value="FISICA"><span class="no-public-inline"></span> Persona Física</mat-radio-button>
              <mat-radio-button value="MORAL">Persona Moral</mat-radio-button>
            </mat-radio-group>
            <mat-form-field matTooltip="{{ tooltipData.nombreInstitucionRazonSocial }}" appearance="fill">
              <mat-label>Nombre/ institución o razón social</mat-label>
              <mat-icon matPrefix class="no-public">group</mat-icon>
              <input
                [errorStateMatcher]="errorMatcher"
                matInput
                type="text"
                formControlName="nombreInstitucion"
                required
              />
            </mat-form-field>
            <mat-form-field matTooltip="{{ tooltipData.rfc }}" appearance="fill">
              <mat-label>RFC</mat-label>
              <mat-icon matPrefix class="no-public">fingerprint</mat-icon>
              <input
                [errorStateMatcher]="errorMatcher"
                matInput
                #input_rfc_otorgante
                formControlName="rfc"
                type="text"
                maxlength="13"
                required
              />
              <mat-hint align="end">{{ input_rfc_otorgante.value?.length || 0 }}/13</mat-hint>
            </mat-form-field>
          </div>

          <div class="grid-2-col grid-margin" formGroupName="localizacionAdeudo">
            <mat-form-field matTooltip="{{ tooltipData.localizaAdeudo }}" appearance="fill">
              <mat-label>¿Dónde se localiza el adeudo?</mat-label>
              <mat-icon matPrefix>public</mat-icon>
              <mat-select (selectionChange)="localizacionChanged($event.value)" required>
                <mat-option value="{{ extranjeroCatalogo[0] }}"> EN MÉXICO </mat-option>
                <mat-option value="{{ extranjeroCatalogo[1] }}"> EN EL EXTRANJERO </mat-option>
              </mat-select>
            </mat-form-field>
            <mat-form-field appearance="fill" *ngIf="tipoDomicilio === 'EX'">
              <mat-label>País donde de ubica</mat-label>
              <mat-icon matPrefix>public</mat-icon>
              <mat-select formControlName="pais" required>
                <mat-option *ngFor="let opt of paisesCatalogo" [value]="opt.clave">
                  {{ opt.valor }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
      </div>

      <mat-divider></mat-divider>

      <div class="boton" *ngIf="!aclaraciones">
        <button mat-raised-button class="text-primary" (click)="toggleAclaraciones(true)">
          AGREGAR ACLARACIONES / OBSERVACIONES
        </button>
      </div>
      <div class="grid-margin" *ngIf="aclaraciones">
        <div class="flex">
          <p>Aclaraciones / Observaciones</p>
          <button mat-raised-button color="primary" (click)="toggleAclaraciones(false)">CANCELAR</button>
        </div>

        <mat-form-field matTooltip="{{ tooltipData.aclaracionesObservaciones }}" appearance="fill" class="grid-margin">
          <mat-icon matPrefix class="aclaraciones-no-public">speaker_notes</mat-icon>
          <textarea
            maxlength="500"
            [errorStateMatcher]="errorMatcher"
            matInput
            formControlName="aclaracionesObservaciones"
            rows="10"
          ></textarea>
        </mat-form-field>
      </div>

      <mat-divider></mat-divider>

      <div class="boton text-right">
        <app-loader class="inline-loader" [isLoading]="isLoading"></app-loader>
        <button mat-raised-button color="primary" type="button" *ngIf="editMode" (click)="cancelEditMode()">
          CANCELAR
        </button>
        <button mat-raised-button color="primary" type="button" (click)="adeudosPasivosForm.reset()">
          LIMPIAR FORMULARIO
        </button>
        <button mat-raised-button color="primary" [disabled]="adeudosPasivosForm.invalid || isLoading">
          GUARDAR CAMBIOS
        </button>
      </div>
    </form>

    <mat-divider></mat-divider>

    <div class="boton center">
      <button mat-raised-button color="primary" (click)="formHasChanges()">
        CONTINUAR 15 DE 15
        <mat-icon matSofix>arrow_forward</mat-icon>
      </button>
    </div>

    <section-footer [declaracionId]="declaracionId"></section-footer>
  </mat-card>
</div>
