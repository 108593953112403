<div fxFill fxLayout="column">
  <app-header></app-header>
  <mat-toolbar class="background-white title-toolbar">
    <label class="step-title">Mi Perfil</label>
  </mat-toolbar>
  <mat-divider class="mg-btm-80px"></mat-divider>

  <mat-card class="contenedor info" *ngIf="!editMode">
    <mat-card-content>
      <div class="size-20">
        <p>
          <span class="negritas">{{ user?.nombre }} {{ user?.primerApellido }} {{ user?.segundoApellido }}</span>
        </p>
      </div>
      <div class="paragraph-flex">
        <mat-icon matPrefix class="icon">fingerprint</mat-icon>
        <p>
          CURP: <span class="resaltar">{{ user?.curp }}</span>
        </p>
      </div>
      <div class="paragraph-flex">
        <mat-icon matPrefix class="icon">work</mat-icon>
        <p>
          RFC: <span class="resaltar">{{ user?.rfc }}</span>
        </p>
      </div>
      <div class="paragraph-flex">
        <mat-icon matPrefix class="icon">email</mat-icon>
        <p>
          Correo Electrónico: <span class="resaltar">{{ user?.username }}</span>
        </p>
      </div>
      <div class="paragraph-flex">
        <mat-icon matPrefix class="icon">date_range</mat-icon>
        <p>
          Fecha de Registro: <span class="resaltar">{{ user?.createdAt | date: 'dd/MM/yyyy' }}</span>
        </p>
      </div>
      <div class="paragraph-flex">
        <mat-icon matPrefix class="icon">supervisor_account</mat-icon>
        <p>
          Rol: <span class="resaltar">{{ user?.roles }}</span>
        </p>
      </div>
      <div class="paragraph-flex" *ngIf="user?.institucion">
        <mat-icon matPrefix class="icon">account_balance</mat-icon>
        <p>
          Institución: <span class="resaltar">{{ user?.institucion.valor }}</span>
        </p>
      </div>
    </mat-card-content>
    <mat-divider></mat-divider>
    <mat-card-actions class="boton-agregar">
      <button mat-button color="primary" (click)="changeEditMode()">Editar</button>
    </mat-card-actions>
  </mat-card>

  <form class="contenedor" (ngSubmit)="confirmChangeProfile()" [formGroup]="profileForm" *ngIf="editMode">
    <div class="grid-3-col">
      <mat-form-field appearance="fill">
        <mat-label>Nombre (s)</mat-label>
        <mat-icon matPrefix>person</mat-icon>
        <input matInput type="text" formControlName="nombre" />
      </mat-form-field>
      <mat-form-field appearance="fill">
        <mat-label>Primer Apellido</mat-label>
        <mat-icon matPrefix>person</mat-icon>
        <input matInput type="text" formControlName="primerApellido" />
      </mat-form-field>
      <mat-form-field appearance="fill">
        <mat-label>Segundo Apellido</mat-label>
        <mat-icon matPrefix>person</mat-icon>
        <input matInput type="text" formControlName="segundoApellido" />
      </mat-form-field>
    </div>
    <div
      class="grid-margin"
      [ngClass]="{ 'grid-2-col': !institucionesCatalogo.length, 'grid-3-col': institucionesCatalogo.length }"
    >
      <mat-form-field [hideRequiredMarker]="true" appearance="fill">
        <mat-label>CURP</mat-label>
        <mat-icon matPrefix>fingerprint</mat-icon>
        <input
          type="text"
          matInput
          formControlName="curp"
          autocomplete="curp"
          [placeholder]="'CURP' | translate"
          required
          #input_curp
          maxlength="18"
        />
        <mat-hint align="end">{{ input_curp.value?.length || 0 }}/18</mat-hint>
        <mat-error *ngIf="profileForm.controls.curp.invalid && profileForm.controls.curp.touched">
          <span translate>CURP requerido</span>
        </mat-error>
      </mat-form-field>

      <mat-form-field [hideRequiredMarker]="true" appearance="fill">
        <mat-label>RFC</mat-label>
        <mat-icon matPrefix>fingerprint</mat-icon>
        <input
          type="text"
          matInput
          formControlName="rfc"
          autocomplete="rfc"
          [placeholder]="'RFC' | translate"
          required
          #input_rfc
          maxlength="13"
        />
        <mat-hint align="end">{{ input_rfc.value?.length || 0 }}/13</mat-hint>
        <mat-error *ngIf="profileForm.controls.rfc.invalid && profileForm.controls.rfc.touched">
          <span translate>RFC requerido</span>
        </mat-error>
      </mat-form-field>

      <mat-form-field *ngIf="institucionesCatalogo.length" [hideRequiredMarker]="true" appearance="fill">
        <mat-label>Institución</mat-label>
        <mat-icon matPrefix>account_balance</mat-icon>
        <mat-select formControlName="institucion" required>
          <mat-option *ngFor="let institucion of institucionesCatalogo" [value]="institucion">
            {{ institucion.ente_publico }}
          </mat-option>
        </mat-select>
        <mat-error *ngIf="profileForm.controls.institucion.invalid && profileForm.controls.institucion.touched">
          <span translate>Institución requerida</span>
        </mat-error>
      </mat-form-field>
    </div>
    <div class="grid-2-col grid-margin">
      <mat-form-field appearance="fill">
        <mat-label>Correo Electrónico</mat-label>
        <mat-icon matPrefix>email</mat-icon>
        <input matInput type="text" [value]="user?.username" disabled />
      </mat-form-field>
      <mat-form-field appearance="fill">
        <mat-label>Contraseña</mat-label>
        <mat-icon matPrefix>fingerprint</mat-icon>
        <input matInput type="password" value="********" disabled />
      </mat-form-field>
    </div>

    <div class="center">
      <button mat-raised-button color="primary" class="btn-guardar" type="button" (click)="editMode = false">
        CANCELAR
      </button>
      <app-loader class="inline-loader" [isLoading]="isLoading"></app-loader>
      <button mat-raised-button color="primary" class="btn-guardar" [disabled]="profileForm.invalid || isLoading">
        GUARDAR
      </button>
    </div>
  </form>

  <mat-divider class="grid-margin"></mat-divider>

  <div class="contenedor boton" *ngIf="!cambiarContrasena">
    <button mat-raised-button class="text-primary" (click)="toggleNuevaContrasena(true)">Cambiar Contraseña</button>
  </div>
  <div class="contenedor" *ngIf="cambiarContrasena">
    <div class="flex grid-margin">
      <p>Cambiar Contraseña</p>
      <button mat-raised-button color="primary" (click)="toggleNuevaContrasena(false)">Cancelar</button>
    </div>

    <form class="contenedor" (ngSubmit)="confirmChangePassword()" [formGroup]="changePasswordForm">
      <div class="grid-3-col grid-margin">
        <mat-form-field appearance="fill">
          <mat-label>Contraseña actual</mat-label>
          <input matInput type="password" formControlName="oldPassword" />
        </mat-form-field>
        <mat-form-field appearance="fill">
          <mat-label>Nueva Contraseña</mat-label>
          <input matInput type="password" formControlName="newPassword" />
        </mat-form-field>
        <mat-form-field appearance="fill">
          <mat-label>Verificar Contraseña</mat-label>
          <input matInput type="password" formControlName="confirmPassword" />
          <mat-error
            *ngIf="
              changePasswordForm.value.confirmPassword.touched &&
              changePasswordForm.value.newPassword !== changePasswordForm.value.confirmPassword
            "
          >
            <span translate>La contraseña no coincide</span>
          </mat-error>
        </mat-form-field>
      </div>

      <div class="center">
        <app-loader class="inline-loader" [isLoading]="isLoading"></app-loader>
        <button
          mat-raised-button
          color="primary"
          class="btn-guardar"
          [disabled]="
            changePasswordForm.invalid ||
            changePasswordForm.value.newPassword !== changePasswordForm.value.confirmPassword ||
            isLoading
          "
        >
          CAMBIAR CONTRASEÑA
        </button>
      </div>
    </form>
  </div>

  <app-footer></app-footer>
</div>
