<ul class="no-pd">
  <li>
    <p class="text-primary question">
      1.- ¿Cómo será tratada toda la información que sea reportada en cada una de las declaraciones?
    </p>
    <p>
      Toda la información contenida en las Declaraciones será visible a través del Sistema; sin embargo, no será
      susceptible de publicidad y se considerará como información clasificada, los datos contenidos en las secciones de
      la declaración patrimonial y de intereses que aparezcan resaltados con una franja de color verde en la parte
      izquierda del campo a llenar (<span class="no-public-inline"></span>).
    </p>
    <p>
      En el caso de menores de edad se deberá privilegiar el interés superior de la niña, el niño y el adolecente, en
      términos de las disposiciones legales aplicables. Por tanto, todos sus datos personales no serán susceptibles de
      publicidad.
    </p>
    <p>
      Los Comités de Transparencia o equivalente a cada Ente Público serán los responsables de clasificar la información
      de las Declaraciones como reservada, cuando su publicidad ponga en riesgo la vida, seguridad o salud de una
      persona, en términos de lo que establezca la normatividad en materia de acceso a la información y trasparencia
      aplicable.
    </p>
  </li>
  <li>
    <p class="text-primary question">
      2.- ¿A quién corresponde la Interpretación y aplicación de la Información reportada en las declaraciones?
    </p>
    <p>
      La interpretación administrativa del formato de Declaraciones y normas, así como su aplicación, corresponde a la
      Secretaría de la Función Pública y sus homólogos en las entidades federativas a través del área encargada de la
      administración del sistema de recepción de Declaraciones.
    </p>
    <p>
      Por lo que se respecta al Poder Legislativo y los órganos autónomos, corresponde a los Órganos Internos de Control
      y sus homólogos en las entidades federativas.
    </p>
    <p>
      Respecto del Poder Judicial de la Federación, corresponde al Pleno de la Suprema Corte de Justicia de la Nación y
      su aplicación a las autoridades encargadas del seguimiento de la evolución patrimonial de los Servidores Públicos
      de dicho poder.
    </p>
  </li>
</ul>
