<div class="section-main-container">
  <mat-card class="form-card">
    <section-header section="II. Declaración de intereses" progress="81" type="{{ tipoDeclaracion }}"></section-header>

    <mat-divider></mat-divider>

    <mat-toolbar class="background-white">
      <label class="step-title"> 3. Apoyos o beneficios públicos (hasta los últimos dos años)</label>
    </mat-toolbar>

    <mat-divider></mat-divider>

    <p>
      Todos los campos son obligatorios. Los datos de terceros, siempre y cuando sean personas físicas, y los campos
      resaltados <span class="no-public-inline"></span> no serán públicos. Todos los datos de la participación en
      empresas, sociedades o asociaciones de <span class="negritas"> la pareja o dependientes económicos</span> no seran
      públicos.
    </p>

    <!-- Botones iniciales -->
    <div class="" *ngIf="!apoyo?.length && !editMode && !apoyosForm.value.ninguno">
      <mat-grid-list cols="2" rowHeight="150px">
        <mat-grid-tile colspan="1">
          <div class="boton-agregar">
            <button mat-raised-button color="primary" (click)="noApoyo()">Ninguno</button>
          </div>
        </mat-grid-tile>
        <mat-grid-tile colspan="1">
          <div class="boton-agregar">
            <button mat-raised-button color="primary" (click)="addItem()">Agregar apoyo o beneficio</button>
          </div>
        </mat-grid-tile>
      </mat-grid-list>
    </div>

    <!-- card de información -->
    <div class="">
      <mat-card class="info" *ngIf="apoyosForm.value.ninguno">
        <p>Ningún apoyo</p>
      </mat-card>

      <mat-card class="info" *ngFor="let a of apoyo; let i = index">
        <mat-card-content class="">
          <div class="grid-3fr-1fr">
            <div class="paragraph-flex">
              <mat-icon matPrefix class="icon">work</mat-icon>
              <p>
                <span class="resaltar">{{ a.beneficiarioPrograma.valor }}</span>
              </p>
            </div>
            <div class="paragraph-flex">
              <mat-icon matPrefix class="icon">dns</mat-icon>
              <p>{{ a.nivelOrdenGobierno | replace: '_':' ' }}</p>
            </div>

            <div class="paragraph-flex size-20">
              <mat-icon matPrefix class="icon">speaker_notes</mat-icon>
              <p>{{ a.nombrePrograma }}</p>
            </div>
            <div class="paragraph-flex">
              <mat-icon matPrefix class="icon">person</mat-icon>
              <p>{{ a.tipoApoyo.valor }}</p>
            </div>
            <div class="paragraph-flex">
              <mat-icon matPrefix class="icon">dns</mat-icon>
              <p>{{ a.institucionOtorgante }}</p>
            </div>
            <div class="paragraph-flex">
              <mat-icon matPrefix class="icon">fingerprint</mat-icon>
              <p>{{ a.montoApoyoMensual.valor | currency }} {{ a.montoApoyoMensual.moneda }}</p>
            </div>
          </div>

          <mat-divider></mat-divider>

          <div class="padding-card-section">
            <div class="paragraph-flex">
              <mat-icon matPrefix class="icon">speaker_notes</mat-icon>
              <p>Especifique el apoyo</p>
            </div>
            <p>
              {{ a.especifiqueApoyo }}
            </p>
          </div>

          <mat-divider></mat-divider>

          <!--<div class="padding-card-section">
            <div class="paragraph-flex aclaraciones">
              <mat-icon matPrefix class="icon">speaker_notes</mat-icon>
              <p>Aclaraciones/Observaciones</p>
            </div>
            <p>
              {{ a.aclaracionesObservaciones }}
            </p>
          </div>
          <mat-divider></mat-divider>-->
        </mat-card-content>
        <mat-card-actions>
          <button mat-button color="primary" (click)="editItem(i)">Modificar</button>
          <button mat-button color="primary" (click)="removeItem(i)">Eliminar</button>
        </mat-card-actions>
      </mat-card>

      <div class="boton-agregar" *ngIf="(apoyo.length && !editMode) || apoyosForm.value.ninguno">
        <button mat-raised-button color="primary" (click)="addItem()">AGREGAR APOYO</button>
      </div>
    </div>

    <!-- Formulario -->
    <form (ngSubmit)="saveItem()" [formGroup]="apoyosForm" *ngIf="editMode">
      <!-- Agregar -->
      <div class="padding-agregar" formGroupName="apoyo">
        <mat-divider></mat-divider>
        <p>Agregar participación</p>
        <!-- Datos -->
        <div class="grid-2-col grid-margin">
          <mat-form-field matTooltip="{{ tooltipData.beneficiario }}" appearance="fill">
            <mat-label>Beneficiario de algún programa</mat-label>
            <mat-icon matPrefix class="no-public">person</mat-icon>
            <mat-select formControlName="beneficiarioPrograma" required>
              <mat-option *ngFor="let beneficiario of beneficiarioProgramaCatalogo" [value]="beneficiario">
                {{ beneficiario.valor }}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field appearance="fill">
            <mat-label>Tipo de persona</mat-label>
            <mat-icon matPrefix>person</mat-icon>
            <mat-select formControlName="tipoPersona" required>
              <mat-option value="FISICA"> FÍSICA </mat-option>
              <mat-option value="MORAL"> MORAL </mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field matTooltip="{{ tooltipData.nombrePrograma }}" appearance="fill">
            <mat-label>Nombre del programa</mat-label>
            <mat-icon matPrefix>dns</mat-icon>
            <input [errorStateMatcher]="errorMatcher" matInput type="text" formControlName="nombrePrograma" required />
          </mat-form-field>

          <mat-form-field matTooltip="{{ tooltipData.institucionOtorgaApoyo }}" appearance="fill">
            <mat-label>Institución que otorga el apoyo</mat-label>
            <mat-icon matPrefix>beenhere</mat-icon>
            <input
              [errorStateMatcher]="errorMatcher"
              matInput
              type="text"
              formControlName="institucionOtorgante"
              required
            />
          </mat-form-field>
          <mat-form-field matTooltip="{{ tooltipData.nivelOrdenGobierno }}" appearance="fill">
            <mat-label>Nivel u orden de gobierno</mat-label>
            <mat-icon matPrefix>dns</mat-icon>
            <mat-select formControlName="nivelOrdenGobierno" required>
              <mat-option *ngFor="let NivelGobierno of NivelGobiernoCatalogo" value="{{ NivelGobierno }}">
                {{ NivelGobierno | replace: '_':' ' }}
              </mat-option>
            </mat-select>
          </mat-form-field>

          <mat-form-field matTooltip="{{ tooltipData.tipoApoyo }}" appearance="fill">
            <mat-label>Tipo de apoyo</mat-label>
            <mat-icon matPrefix>dns</mat-icon>
            <mat-select formControlName="tipoApoyo" required>
              <mat-option *ngFor="let tipoApoyo of TiposApoyoCatalogo" [value]="tipoApoyo">
                {{ tipoApoyo.valor }}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field
            appearance="fill"
            class="double-col"
            *ngIf="apoyosForm.value.apoyo.tipoApoyo?.clave === 'OTRO'"
          >
            <mat-label>Especifique</mat-label>
            <mat-icon matPrefix class="no-public">dns</mat-icon>
            <input [errorStateMatcher]="errorMatcher" matInput type="text" required />
          </mat-form-field>
          <mat-form-field matTooltip="{{ tooltipData.formaRecepcionApoyo }}" appearance="fill">
            <mat-label>Forma de recepción de pago</mat-label>
            <mat-icon matPrefix>public</mat-icon>
            <mat-select formControlName="formaRecepcion" required>
              <mat-option *ngFor="let recepcionApoyo of recepcionApoyoCatalogo" value="{{ recepcionApoyo }}">
                {{ recepcionApoyo }}
              </mat-option>
            </mat-select>
          </mat-form-field>

          <mat-form-field
            matTooltip="{{ tooltipData.montoAproximado }}"
            appearance="fill"
            formGroupName="montoApoyoMensual"
          >
            <mat-label>Monto aproximado del apoyo mensual</mat-label>
            <mat-icon matPrefix>beenhere</mat-icon>
            <input [errorStateMatcher]="errorMatcher" matInput type="number" formControlName="valor" min="0" required />
          </mat-form-field>
          <mat-form-field appearance="fill">
            <mat-label>Especifique el apoyo</mat-label>
            <mat-icon matPrefix>account_balance_wallet</mat-icon>
            <input
              [errorStateMatcher]="errorMatcher"
              matInput
              type="text"
              formControlName="especifiqueApoyo"
              required
            />
          </mat-form-field>
        </div>
      </div>

      <mat-divider></mat-divider>

      <div class="boton" *ngIf="!aclaraciones">
        <button mat-raised-button class="text-primary" (click)="toggleAclaraciones(true)">
          AGREGAR ACLARACIONES / OBSERVACIONES
        </button>
      </div>
      <div *ngIf="aclaraciones">
        <div class="flex grid-margin">
          <p>Aclaraciones / Observaciones</p>
          <button mat-raised-button color="primary" (click)="toggleAclaraciones(false)">CANCELAR</button>
        </div>

        <mat-form-field matTooltip="{{ tooltipData.aclaracionesObservaciones }}" appearance="fill" class="grid-margin">
          <mat-icon matPrefix class="aclaraciones-no-public">speaker_notes</mat-icon>
          <textarea
            maxlength="500"
            [errorStateMatcher]="errorMatcher"
            matInput
            formControlName="aclaracionesObservaciones"
            rows="10"
          ></textarea>
        </mat-form-field>
      </div>

      <mat-divider></mat-divider>

      <div class="boton text-right">
        <app-loader class="inline-loader" [isLoading]="isLoading"></app-loader>
        <button mat-raised-button color="primary" type="button" *ngIf="editMode" (click)="cancelEditMode()">
          CANCELAR
        </button>
        <button mat-raised-button color="primary" type="button" (click)="apoyosForm.reset()">LIMPIAR FORMULARIO</button>
        <button mat-raised-button color="primary" [disabled]="apoyosForm.invalid || isLoading">GUARDAR CAMBIOS</button>
      </div>
    </form>

    <mat-divider></mat-divider>

    <div class="boton center">
      <button mat-raised-button color="primary" (click)="formHasChanges()">
        CONTINUAR 4 DE 7
        <mat-icon matSofix>arrow_forward</mat-icon>
      </button>
    </div>

    <section-footer [declaracionId]="declaracionId"></section-footer>
  </mat-card>
</div>
