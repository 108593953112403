<ul class="no-pd">
  <li>
    <p class="text-primary question">1.- ¿Cuál es la documentación sugerida para el llenado de las declaraciones?</p>
    <ul class="no-mg">
      <li>
        <span class="resaltar">a) </span>Clave Única de Registro de Población (CURP) emitida por la Secretaría de
        Gobernación.
      </li>
      <li>
        <span class="resaltar">b) </span>Registro Federal de Contribuyentes (RFC) emitido por el Servicio de
        Administración Tributaria (SAT).
      </li>
      <li><span class="resaltar">c) </span>Acta de matrimonio.</li>
      <li><span class="resaltar">d) </span>Comprobante de domicilio.</li>
      <li><span class="resaltar">e) </span>Currículo vitae</li>
      <li><span class="resaltar">f) </span>Recibo de nómina y/o declaración fiscal.</li>
      <li><span class="resaltar">g) </span>Escrituras públicas y/o contratos de bienes inmuebles.</li>
      <li><span class="resaltar">h) </span>Factura de vehículos y bienes muebles.</li>
      <li><span class="resaltar">i) </span>Contratos y estados de cuanta bancarios.</li>
      <li><span class="resaltar">k) </span>Contratos y estados de cuenta de gravámenes y adeudos.</li>
      <li><span class="resaltar">j) </span>Comprobantes de percepción de sueldo o de otro tipo de ingreso.</li>
      <li><span class="resaltar">l) </span>Actas cosntitutivas de sociedades y asociaciones.</li>
      <li><span class="resaltar">m) </span>Cualquier otro documento necesario para las Declaraciones.</li>
    </ul>
  </li>
  <li>
    <p class="text-primary question">2.- ¿Cómo se deben llenar las secciones del apartado "Declaración patrimonial"?</p>
    <p>
      <span class="resaltar">Para la declaración de inicio</span>, reportar los datos del empleo, cargo o comisión que
      inicie.
    </p>
    <p>
      <span class="resaltar">Para la declaración de modificación</span>, deberá reportar el empleo, cargo o comisión
      actual
    </p>
    <p>
      <span class="resaltar">Para la declaración de conclusión</span>, reportar los datos del empleo, pargo o comisión
      que concluya.
    </p>
  </li>
  <li>
    <p class="text-primary question">
      3.- ¿Cómo se deben llenas las secciones del apartado "Declaración de Intereses"?
    </p>
    <p>
      <span class="resaltar">Para la declaración de inicio</span>, reportar la situación de cada uno de los rubros
      siguientes a la fecha de ingreso al empleo, cargo o comisión.
    </p>
    <p>
      <span class="resaltar">Para la declaración de modificación</span>, reportar la situación de cada uno de los rubros
      siguientes del año inmediato anterior.
    </p>
    <p>
      <span class="resaltar">Para la declaración de conclusión</span>, reportar la situación de cada uno de los rubros
      siguientes a la fecha de conclusión del empleo, cargo o comisión
    </p>
    <p>Deberá reportar hasta los últimos dos años.</p>
  </li>
  <li>
    <p class="text-primary question">
      4.- Si deseas consultar los formatos aprobados por el Comité Coordinador del Sistema Nacional Anticorrupción para
      saber más, haz click en el siguiente
      <a class="link" href="https://www.dof.gob.mx/nota_detalle.php?codigo=5573194&fecha=23%2F09%2F2019" target="_blank"
        >link</a
      >."
    </p>
  </li>
</ul>
