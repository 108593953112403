<mat-toolbar class="info-section">
  <mat-grid-list cols="4" rowHeight="300px">
    <mat-grid-tile [colspan]="2">
      <div class="section-container">
        <img class="pdn-img" src="assets/pdn-white.png" alt="PDN" />
        <label class="pdn-text">Plataforma Digital Nacional</label>
        <br /><br />
        <label class="ida-text">Inteligencia de Datos Anticorrupción</label>
        <img class="libre-uso-img" src="assets/libre-uso-mx-white.png" alt="Libre Uso MX" />
      </div>
    </mat-grid-tile>
    <mat-grid-tile [colspan]="1">
      <div class="section-container links-section">
        <p class="primary-text">Sistemas</p>
        <a class="menu-button" mat-button href="https://plataformadigitalnacional.org/declaraciones" target="_blank"
          >Declaraciones</a
        >
        <a class="menu-button" mat-button href="https://plataformadigitalnacional.org/servidores" target="_blank"
          >Servidores en contrataciones</a
        >
        <a class="menu-button" mat-button href="https://plataformadigitalnacional.org/sancionados" target="_blank"
          >Sancionados</a
        >
        <a class="menu-button" mat-button href="https://plataformadigitalnacional.org/contrataciones" target="_blank"
          >Contrataciones</a
        >
      </div>
    </mat-grid-tile>
    <mat-grid-tile [colspan]="1">
      <div class="section-container links-section">
        <p class="primary-text">PDN</p>
        <a class="menu-button" mat-button href="https://plataformadigitalnacional.org/blog/" target="_blank">Blog</a>
        <a class="menu-button" mat-button href="https://plataformadigitalnacional.org/faq" target="_blank"
          >Preguntas frecuentes</a
        >
        <a class="menu-button" mat-button href="https://plataformadigitalnacional.org/about" target="_blank"
          >¿Qué es la PDN?</a
        >
        <a class="menu-button" mat-button href="https://plataformadigitalnacional.org/terminos" target="_blank"
          >Términos de uso</a
        >
      </div>
    </mat-grid-tile>
  </mat-grid-list>
</mat-toolbar>
<mat-toolbar class="sna">
  <img src="assets/bid.png" alt="BID" />
  <img src="assets/sna.png" alt="SNA" />
  <img src="assets/sertech.png" alt="Sertech" />
</mat-toolbar>
