<ul class="no-pd">
  <li>
    <p class="text-primary question">1.- ¿Cuáles son los plazos de presentación de las declaraciones?</p>
    <p>
      <span class="resaltar">Inicial: </span>Dentro de los sesenta días naturales siguientes a la toma de posesión con
      motivo del: ingreso al servicio público por primera vez o reingreso al servicio público después de sesenta días
      naturales de la conclusión del último encargo.
    </p>
    <p>
      <span class="resaltar">Modificación: </span>Durante el mes de mayo de cada año, siempre y cuando haya laborado al
      menos un día del año inmediato anterior.
    </p>
    <p>
      <span class="resaltar">Conclusión: </span>Dentro de los sesenta días naturales siguientes a la conclusión del
      encargo.
    </p>
  </li>
  <li>
    <p class="text-primary question">
      2.- ¿Cuándo se presentarán las declaraciones en caso de cambio de Ente Público en el mismo orden de gobierno?
    </p>
    <p>
      Únicamente se dará aviso de dicha situación, dentro de los sesenta días naturales posteriores a la fecha de toma
      de posesión del nuevo encargo, y no será necesario presentar la declaración inicial, ni la de conclusión. El aviso
      incluirá la información sobre los datos generales del servidor público del nuevo encargo.
    </p>
  </li>
  <li>
    <p class="text-primary question">3.- ¿Cuáles son los medios de presentación de las declaraciones?</p>
    <p>
      Las declaraciones deberán ser presentadas a través de medios electrónicos, empleándose FIEL o usuario y
      contraseña.
    </p>
  </li>
  <li>
    <p class="text-primary question">4.-¿Qué periodo es el que será reportado?</p>

    <p>
      <span class="resaltar">En la declaración de modificación</span> se reportará sobre las modificaciones que haya
      sufrido el patrimonio del servidor público, durante el periodo comprendido del 1 de enero al 31 de diciembre del
      año inmediato anterior.
    </p>
    <p>
      <span class="resaltar">Las declaraciones de inicio y conclusión</span> deberán reportarse con la situación a la
      fecha del alta o baja del empleo, cargo o comisión en el servicio público, según corresponda
    </p>
  </li>
  <li>
    <p class="text-primary question">5.- ¿Cuándo NO se presentará declaración inicial?</p>

    <p>
      <span class="resaltar">a) </span>Cuando el servidor público en el mismo Ente Público sea objeto de un cambio de
      puesto, de nivel, de funciones, de adscripción, tipo de designación o nombramiento.
    </p>
    <p>
      <span class="resaltar">b) </span>Cuando el servidor público regrese o sea contratado y no haya transcurrido más de
      sesenta días naturales.
    </p>
    <p>
      <span class="resaltar">c) </span>Cuando el servidor público tenga un cambio de Ente Público dentro del mismo orden
      de gobierno, y no transcurran más de sesenta días naturales entre la conclusión e inicio del empleo, cargo o
      comisión, debiendo cumplir con el aviso correspondiente.
    </p>
    <p>
      <span class="resaltar">d) </span>Cuando el servidor público regrese al empleo, cargo o comisión con motivo del
      otorgamiento de una licencia con o sin goce de sueldo, derive de una suspensión en sueldo y/o funciones, o sea
      resultado de una restitución de derechos como servidor público mediante resolución ejecutoriada firme, expedida
      por autoridad competente en el ejercicio de sus funciones.
    </p>
  </li>
  <li>
    <p class="text-primary question">6.- ¿Cuándo No se presentará declaración de modificación?</p>
    <p>
      <span class="resaltar">a) </span>Cuando durante los primeros cinco meses del año los Servidores Públicos tomen
      posesión del empleo, cargo o comisión y presenten su declaración patrimonial de inicio en el mismo período.
    </p>
    <p>
      <span class="resaltar">b) </span>Cuando el servidor público concluya su empleo, cargo o comisión en el mes de mayo
      y hubiese presentado su declaración de conclusión en el mismo mes.
    </p>
  </li>
  <li>
    <p class="text-primary question">7.- ¿Cuándo NO se presentará declaración de conclusión?</p>
    <p>
      <span class="resaltar">a) </span>Cuando el servidor público en el mismo Ente Público sea objeto de un cambio de
      puesto, de nivel, de funciones, de adscripción, tipo de designación o nombramiento.
    </p>
    <p>
      <span class="resaltar">b) </span>Cuando el servidor público, concluya e inicie en Entes Públicos, dentro del mismo
      orden de gobierno y no hayan transcurrido más de sesenta días naturales
    </p>
    <p>
      <span class="resaltar">c) </span>Cuando al servidor público le haya sido otorgada una licencia con o sin goce de
      sueldo, siempre y cuando no haya sido dado de baja de manera definitiva del Ente Público o derive de una
      suspensión en sueldo y/o funciones.
    </p>
  </li>
  <li>
    <p class="text-primary question">8.- ¿Cuáles son los mecanismos de firmado y envío de las Declaraciones?</p>
    <p><span class="resaltar">a) </span>Utilizando la FIEL.</p>
    <p><span class="resaltar">b) </span>Utilizando usuario y contraseña</p>
  </li>
  <li>
    <p class="text-primary question">9.- ¿Quiénes presentarán declaración patrimonial y de intereses?</p>
    <p>
      Aquellos Servidores Públicos que tengan nivel igual a jefe de departamento u homólogo y hasta el nivel máximo en
      cada Ente Público y sus homólogos en las entidades federativas, municipios y alcaldías presentarán declaración
      patrimonial y de intereses en su totalidad.
    </p>

    <p>
      Aquellos Servidores Públicos que tengan nivel menor a jefe de departamento y homólogo en los Entes Públicos y sus
      homólogos en las entidades federativas, presentarán declaración patrimonial y de intereses, reportando los
      siguientes rubros:
    </p>
    <ul>
      <li>a) Datos Generales.</li>
      <li>b) Domicilio del Declarante.</li>
      <li>c) Datos Curriculares.</li>
      <li>d) Dato de empleo, cargo o comisión.</li>
      <li>e) Experiencia laboral</li>
      <li>f) Ingresos netos del Declarante.</li>
      <li>g) ¿Te desempeñaste como servidor público el año inmediato anterior?</li>
    </ul>
  </li>
</ul>
