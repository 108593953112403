<div fxFill fxLayout="column" fxLayoutAlign="center">
  <app-header></app-header>
  <mat-toolbar class="background-white title-toolbar">
    <label class="step-title">Restablecer contraseña</label>
  </mat-toolbar>
  <mat-divider></mat-divider>
  <div class="login-container" fxLayout="row" fxLayoutAlign="center center">
    <mat-card fxFlex="40%" class="login-box">
      <p>Escribe tu nueva contraseña</p>

      <form (ngSubmit)="resetPassword()" [formGroup]="passwordForm" novalidate>
        <!--<div class="login-fields" fxLayout="column">-->
        <div class="login-fields">
          <mat-form-field [hideRequiredMarker]="true" appearance="fill">
            <mat-label>Nueva contraseña</mat-label>
            <mat-icon matPrefix>lock</mat-icon>
            <input
              type="password"
              matInput
              formControlName="password"
              [placeholder]="'Nueva contraseña' | translate"
              maxlength="20"
              required
            />
          </mat-form-field>

          <mat-form-field [hideRequiredMarker]="true" appearance="fill">
            <mat-label>Confirma tu contraseña</mat-label>
            <mat-icon matPrefix>lock</mat-icon>
            <input
              type="password"
              matInput
              formControlName="confirmPassword"
              [placeholder]="'Confirma tu contraseña' | translate"
              maxlength="20"
              required
            />
            <mat-error
              align="end"
              *ngIf="
                passwordForm.controls.confirmPassword.touched &&
                passwordForm.value.password !== passwordForm.value.confirmPassword
              "
            >
              <span translate>La contraseña no coincide</span>
            </mat-error>
          </mat-form-field>

          <button
            mat-raised-button
            class="login-btn"
            color="primary"
            type="submit"
            [disabled]="
              passwordForm.invalid || passwordForm.value.password !== passwordForm.value.confirmPassword || isLoading
            "
          >
            <app-loader class="inline-loader" [isLoading]="isLoading"></app-loader>
            <span translate>CAMBIAR CONTRASEÑA</span>
          </button>
        </div>
      </form>
    </mat-card>
  </div>
  <app-footer></app-footer>
</div>
